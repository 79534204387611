.footer {
    text-align: center;
    padding: 20px;
    background-color: #f8f4ff; /* lilás pastel claro */
    border-radius: 8px;
    margin-bottom: 20px;
    position: relative;
    bottom: 0;
    border-top: 2px solid #d2c4e2; /* Linha de separação lilás mais escura */
  }
  
  .footer-content {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .footer-text {
    margin: 5px 0;
    font-size: 14px;
  }
  
  .footer-link {
    color: #4a3c6f; /* Cor dos links */
    text-decoration: none;
    margin: 0 10px;
  }
  
  .footer-link:hover {
    text-decoration: underline;
  }
  