/* Estilo geral do card de poema *

/* Estilo do corpo do card */
.card-body {
  padding: 20px; /* Padding interno do card */
  color: #4a4a4a; /* Cor padrão do texto */
}

/* Estilo do título do poema */
.card-title {
  margin: 0 0 10px; /* Margem inferior para separar do conteúdo */
  font-size: 1.6em; /* Tamanho do texto do título */
  font-weight: 600; /* Peso da fonte para destaque */
  color: #4a4a4a; /* Cor escura para o título */
}

/* Estilo para o texto do poema */
.card-text {
  margin: 0;
  font-size: 1em; /* Tamanho do texto do poema */
  color: #6c757d; /* Cor do texto do poema */
  line-height: 1.6; /* Altura da linha para melhor legibilidade */
  transition: max-height 0.3s ease, opacity 0.3s ease; /* Transição suave para expandir/recolher */
  overflow: hidden; /* Garante que o texto não ultrapasse os limites do card */
  max-height: 150px; /* Altura máxima para texto não expandido */
}

/* Estilo para o texto do poema quando expandido */
.card-text.expanded {
  max-height: none; /* Remove a altura máxima quando expandido */
}

/* Estilo para o autor do poema */
.text-muted {
  margin-top: 10px;
  font-size: 0.9em; /* Tamanho do texto do autor */
  color: #9a9a9a; /* Cor do texto do autor */
  font-style: italic; /* Estilo itálico para o nome do autor */
}

/* Estilo para o botão de ler mais/menos */


/* Estilos responsivos */
@media (max-width: 768px) {
  .card-title {
    font-size: 1.4em; /* Ajusta o tamanho do título em telas menores */
  }

  .card-text {
    font-size: 0.9em; /* Ajusta o tamanho do texto do poema em telas menores */
  }
}