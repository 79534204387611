/* Container geral da biografia */
.biography {
  max-width: 900px; /* Limita a largura máxima */
  margin: 0 auto; /* Centraliza o conteúdo */
  padding: 40px; /* Adiciona espaçamento interno */
  background-color: #f8f4ff; /* Fundo lilás pastel muito suave */
  border-radius: 12px; /* Bordas arredondadas */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1); /* Sombra suave para profundidade */
  font-family: 'Merriweather', serif; /* Fonte elegante e legível */
  line-height: 1.8; /* Aumenta a altura da linha para melhor legibilidade */
  color: #4a3b6d; /* Lilás escuro para o texto */
  transition: box-shadow 0.3s ease; /* Animação suave na sombra */
  margin-bottom: 30px;
}

.biography .container-body .image-container {
float: left;
margin-right: 20px; /* Espaço entre a imagem e o texto */
max-width: 100%; /* Definindo a largura máxima da imagem */
margin-top: 10px;
}

.biography .container-body .image-container img {
width: 300px;  /* Define o tamanho da imagem */
height: auto;  /* Mantém a proporção da imagem */
border-radius: 8px;
}

/* Estilo para o parágrafo */
.biography .container-body p {
  font-size: 1.15em; /* Tamanho de texto confortável */
  color: #4a3b6d; /* Texto em lilás escuro para contraste */
  text-align: justify; /* Texto justificado para um visual simétrico */
}
  

.biography:hover {
  box-shadow: 0 10px 28px rgba(0, 0, 0, 0.15); /* Eleva e dá destaque ao hover */
}



/* Estilo para o título */
.biography h2 {
  font-size: 2.2em; /* Tamanho maior para o título */
  font-weight: 600; /* Negrito suave */
  color: #6d5ba5; /* Lilás pastel mais forte */
  text-align: center; /* Centraliza o título */
  margin-bottom: 30px; /* Espaçamento inferior */
  text-transform: capitalize; /* Capitaliza o título */
}

.biography .btn-back {
  font-size: 1em; /* Tamanho do texto confortável */
  color: #a89ecf; /* Cor lilás suave para o botão */
  text-decoration: none; /* Remove o sublinhado do link */
  padding: 5px; /* Padding interno do botão */
  background-color: #ece8ff; /* Fundo lilás suave */
  border-radius: 8px; /* Bordas arredondadas para suavidade */
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease; /* Transições suaves */
  cursor: pointer; /* Muda o cursor para pointer */
  text-align: center; /* Centraliza o texto */
  font-family: 'Merriweather', serif;
}

.btn-back:hover {
  background-color: #d8b4e2; /* Tom lilás mais claro ao hover */
  color: #ffffff; /* Texto branco ao passar o mouse */
  transform: scale(1.05); /* Leve aumento ao hover */
}

/* Estilo responsivo */
@media (max-width: 768px) {
  .biography {
    padding: 20px; /* Reduz o padding em telas menores */
  }

  .biography h2 {
    font-size: 1.5em; /* Reduz o tamanho do título em telas menores */
  }

  .biography p {
    font-size: 1em; /* Ajusta o tamanho do texto para melhor leitura */
  }

  .biography .container-body .image-container {
    float: none; /* Remove o float para centralizar a imagem */
    margin-right: 0; /* Remove o espaçamento entre a imagem e o texto */
    margin-bottom: 20px; /* Adiciona espaçamento inferior */
  }

  .biography .container-body .image-container img {
    width: 100%; /* Define a largura da imagem para 100% */
    max-width: 250px; /* Limita a largura máxima da imagem */
  }

  .biography .container-body {
    text-align: center; /* Centraliza o texto */
  }

}