.div-tela {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: auto;
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Estilos para as partes */
.div-tela .div-parte1,
.div-tela .div-parte2 {
    flex: 1; /* Permite que as partes cresçam igualmente */
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* Cores de fundo */
.div-tela .div-parte1 {
    background-color: #f8f4ff;
}

.div-tela .div-parte2 {
    background-color: #f8f6fc;
}

/* Estilo da imagem */
.div-tela .div-parte2 img {
    width: 100%; /* Ajusta a largura para o tamanho do contêiner */
    max-width: 480px;
    height: auto;
    border-radius: 4px;
}

.div-tela .div-parte1 p {
    font-size: 1.15em; /* Tamanho de texto confortável */
    color: #4a3b6d; /* Texto em lilás escuro para contraste */
    text-align: justify; /* Texto justificado para um visual simétrico */
    padding-left: 15px; /* Pequena indentação no início */
  }

/* Responsividade */
@media (max-width: 768px) {
    .div-tela {
        flex-direction: column; /* Muda para coluna em telas menores */
        height: auto; /* Ajusta a altura */
    }

    .div-tela .div-parte1,
    .div-tela .div-parte2 {
        width: 100%; /* Faz com que cada parte ocupe 100% da largura */
        height: auto; /* Ajusta a altura para auto */
    }
}

@media (max-width: 480px) {
    .div-tela {
        padding: 10px; /* Adiciona um pouco de espaço nas bordas em telas pequenas */
    }

    .div-tela .div-parte1 img,
    .div-tela .div-parte2 img {
        max-width: 100%; /* Garante que a imagem se ajuste em telas menores */
    }
}
