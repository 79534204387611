.head {
  text-align: center;
  padding: 10px;
  background-color: #f8f4ff; /* lilás pastel claro */
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.head a.btn-title {
  font-size: 2rem;
  color: #7a5e9a; /* tom lilás mais escuro para contraste */
  font-weight: bold;
  margin-top: 10px;
  letter-spacing: 1px;
  text-decoration: none;
}

.head p {
  font-size: 1.1rem;
  color: #8b709e; /* Lilás mais claro */
  text-align: center;
  padding: 10px;
}

.head img {
  width: 100%;
  max-width: 300px;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.opcoes-btn {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.btn-opcao {
  background-color: #c4a1d7; /* botões com fundo lilás pastel mais saturado */
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 50px;
  font-size: 1rem;
  text-decoration: none;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.btn-opcao:hover {
  background-color: #ab7eb5; /* Efeito hover com uma tonalidade mais forte */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
}
