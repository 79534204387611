/* Container da lista de poemas */
.poem-list {
  display: flex; /* Alinha os itens em linha */
  flex-wrap: wrap; /* Permite que os itens quebrem linha */
  gap: 20px; /* Espaçamento entre os cards */
  justify-content: center; /* Centraliza os cards na tela */
  padding: 20px; /* Espaçamento interno */
}

/* Estilo para o card individual */
.single-card {
  background-color: #f8f4ff; /* Fundo lilás pastel suave */
  border-radius: 12px; /* Bordas arredondadas */
  padding: 20px; /* Espaçamento interno */
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.08); /* Sombra sutil */
  max-width: 320px; /* Limita a largura máxima */
  text-align: center; /* Centraliza o texto */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Animação suave */
  font-family: 'Merriweather', serif; /* Fonte refinada */
}

/* Efeito hover para o card */
.single-card:hover {
  transform: translateY(-5px); /* Eleva o card ao passar o mouse */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.12); /* Aumenta a sombra no hover */
}

/* Título do poema */
.single-card h5 {
  font-size: 1.5em; /* Tamanho do título maior */
  color: #6d5ba5; /* Lilás pastel mais forte */
  margin-bottom: 15px; /* Espaçamento inferior */
  font-weight: 600; /* Título em negrito */
}

/* Conteúdo do poema (excerto) */
.single-card p {
  font-size: 1.1em; /* Tamanho confortável para o texto */
  color: #4a3b6d; /* Lilás escuro para contraste */
  margin-bottom: 20px; /* Espaçamento inferior */
  line-height: 1.6; /* Aumenta a altura da linha para legibilidade */
}

/* Nome do autor */
.single-card .poem-author {
  font-size: 1em; /* Tamanho do texto do autor */
  color: #a08cc6; /* Tom mais claro para destacar menos */
  font-style: italic; /* Estilo itálico para o autor */
  margin-bottom: 25px; /* Espaçamento inferior */
}

/* Botão "Ler mais" */
.btn-read {
  display: inline-block; /* Botão inline */
  padding: 10px 25px; /* Espaçamento interno */
  background-color: #6d5ba5; /* Lilás mais forte para o botão */
  color: #fff; /* Texto branco no botão */
  border-radius: 25px; /* Botão arredondado */
  text-decoration: none; /* Remove o sublinhado */
  font-size: 1em; /* Tamanho do texto */
  font-weight: 500; /* Peso intermediário para o texto */
  transition: background-color 0.3s ease; /* Animação suave */
}

/* Efeito hover no botão */
.btn-read:hover {
  background-color: #5b4795; /* Lilás mais escuro ao passar o mouse */
}

/* Estilo responsivo para dispositivos menores */
@media (max-width: 768px) {
  .poem-list {
    gap: 15px; /* Reduz o espaçamento entre cards */
  }

  .single-card {
    max-width: 100%; /* Card ocupa toda a largura da tela */
    padding: 15px; /* Reduz o padding em telas menores */
  }

  .single-card h5 {
    font-size: 1.3em; /* Ajusta o tamanho do título */
  }

  .single-card p {
    font-size: 1em; /* Ajusta o tamanho do conteúdo */
  }

  .btn-read {
    padding: 8px 20px; /* Ajusta o tamanho do botão */
  }
}
