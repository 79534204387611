/* Estilos para o container do poema individual */
.single-poem {
    max-width: 800px; /* Limita a largura máxima da exibição do poema */
    margin: 0 auto; /* Centraliza o conteúdo horizontalmente */
    background-color: #fafafa; /* Fundo suave, para destacar o texto */
    border-radius: 10px; /* Bordas arredondadas */
    padding: 20px; /* Padding interno para espaçamento */
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1); /* Sombra leve para profundidade */
    font-family: 'Georgia', serif; /* Fonte clássica para elegância */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Animação suave */
  }
  
  .single-poem:hover {
    transform: translateY(-5px); /* Leve elevação ao passar o mouse */
    box-shadow: 0 10px 28px rgba(0, 0, 0, 0.15); /* Sombra mais acentuada ao hover */
  }
  
  /* Estilos para o título do poema */
  .poem-title {
    font-size: 1.8em; /* Tamanho grande para destaque do título */
    font-weight: 600; /* Negrito suave para dar ênfase */
    color: #4a4a4a; /* Cor escura para contraste */
    margin-bottom: 20px; /* Espaçamento abaixo do título */
    text-align: center; /* Centraliza o título */
    line-height: 1.4; /* Altura da linha para legibilidade */
  }
  
  /* Estilos para o conteúdo do poema */
  .poem-full-content {
    font-size: 1.2em; /* Tamanho confortável para leitura */
    white-space: pre-wrap; /* Mantém as quebras de linha do poema */
    color: #5c5c5c; /* Tom neutro de cinza para o conteúdo */
    line-height: 1.8; /* Aumenta a altura da linha para fluidez */
    margin-bottom: 30px; /* Espaçamento entre o conteúdo e o autor */
    padding-left: 20px; /* Leve indentação à esquerda para formato de poema */
    text-align: center; /* Texto justificado para um visual simétrico */
    font-family: 'Georgia', serif; /* Fonte clássica para elegância */
  }
  
  /* Estilos para o autor do poema */
  .poem-author {
    font-size: 1.1em; /* Um pouco menor que o conteúdo */
    color: #9a9a9a; /* Cor mais clara para o nome do autor */
    font-style: italic; /* Estilo itálico para destacar o nome */
    text-align: right; /* Alinha o autor à direita */
    margin-bottom: 20px; /* Espaçamento inferior para o botão */
  }
  
  /* Estilos para o botão de voltar */
  .btn-back {
    display: inline-block; /* Permite controle de padding e margens */
    font-size: 1em; /* Tamanho do texto confortável */
    color: #a89ecf; /* Cor lilás suave para o botão */
    text-decoration: none; /* Remove o sublinhado do link */
    padding: 10px 20px; /* Padding interno do botão */
    background-color: #ece8ff; /* Fundo lilás suave */
    border: 2px solid #a89ecf; /* Borda lilás escura */
    border-radius: 8px; /* Bordas arredondadas para suavidade */
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease; /* Transições suaves */
    cursor: pointer; /* Muda o cursor para pointer */
    text-align: center; /* Centraliza o texto */
  }
  
  .btn-back:hover {
    background-color: #d8b4e2; /* Tom lilás mais claro ao hover */
    color: #ffffff; /* Texto branco ao passar o mouse */
    transform: scale(1.05); /* Leve aumento ao hover */
  }
  