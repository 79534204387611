/* src/App.css */

.app {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

/* Estilos responsivos para a tela inteira */
@media (max-width: 768px) {
  .container {
    padding: 15px;
  }
}
